import { ILabelProps, TextField } from "@fluentui/react";
import { useAtom, useAtomValue } from "jotai";
import { Label } from "office-ui-fabric-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useUserData } from "../../../../hooks/data/queries/useUserData";
import useAppTheme from "../../../../hooks/useAppTheme";
import { formatDateString } from "../../../../library/utils";
import { contactPersonsState, selectedClientState } from "../../../../store";
import ClientSearch from "./ClientSearch";

function Info() {
  const { t } = useTranslation();
  const { data } = useUserData();
  const clientData = useAtomValue(selectedClientState);
  const [contactPersons, setContactPersons] = useAtom(contactPersonsState);

  const grecoEmployee = data?.displayName;
  const theme = useAppTheme();

  const labelProps: Partial<ILabelProps> = {
    styles: {
      root: { color: theme.palette.neutralPrimaryAlt, fontSize: "15px" },
    },
    // styles: { root: { color: theme.palette.neutralTertiary } },
  };
  return (
    <Container>
      <MainDataWrap>
        <ClientWrap>
          <Label required {...labelProps}>
            {t("esg.clientName.label")}
          </Label>
          <ClientSearch />
        </ClientWrap>
        <ItemWrap
          style={{
            width: "400px",
            justifyContent: "end",
          }}
        >
          <Label {...labelProps} required>
            {t("esg.nameOfContactPersonAtClient.label")}
          </Label>
          <TextField
            value={contactPersons}
            onChange={(_, newValue) => {
              setContactPersons(newValue);
            }}
            underlined
            styles={{
              root: {
                background: "transparent",
                width: "100%",
              },
              fieldGroup: {
                background: "transparent",
              },
            }}
            placeholder={t("esg.nameOfContactPersonsPlaceholder.label")}
          />
        </ItemWrap>
      </MainDataWrap>

      <ItemWrap>
        <Label {...labelProps}>{t("esg.date.label")}</Label>
        <Text>
          {formatDateString(new Date().toString() || new Date().toISOString())}
        </Text>
      </ItemWrap>
      <ItemWrap>
        <Label {...labelProps}>{t("esg.clientCompany.label")}</Label>
        <Text>{clientData?.name}</Text>
      </ItemWrap>
      <ItemWrap>
        <Label {...labelProps}>{t("esg.grecoEmployee.label")}</Label>
        <Text
          style={{
            color: theme.palette.neutralPrimaryAlt,
          }}
        >
          {grecoEmployee}
        </Text>
      </ItemWrap>
    </Container>
  );
}

export default Info;

const Container = styled.div`
  display: flex;
  grid-row-gap: 50px;
  position: sticky;
  justify-content: space-between;
  z-index: 5;
  width: 1300px;
  margin: 0 auto;
  background: white;
  padding: 50px 20px 40px;
  top: 0;
  background-color: ${(props) => props.theme.palette.themeBackground};
  flex-wrap: wrap;
`;

const MainDataWrap = styled.div`
  display: flex;
  width: 100%;
  gap: 50px;
`;

const ItemWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
`;

const ClientWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 400px;
`;

const Text = styled.div`
  color: ${({ theme }) => theme.palette.neutralPrimaryAlt};
  font-size: 18px;
`;
