import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import { AccountInfo } from "@azure/msal-browser";
import { Suspense } from "react";
import "./index.css";
import { MSGraphAuth } from "./service/GraphService";
import { GrecoSpinner } from "@greco/components";
import { QueryClientProvider, QueryClient } from "react-query";

import "@greco/components/dist/index.css";
import { initializeIcons } from "@fluentui/react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const APP_URL =
  process.env.REACT_APP_APP_URL ||
  `${window.location.protocol}${window.location.host}`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

const startApplication = () => {
  initializeIcons();
  root.render(
    <React.Fragment>
      <Suspense fallback={<GrecoSpinner />}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Suspense>
    </React.Fragment>
  );
};

MSGraphAuth.accountObj
  .then((accountInfo: AccountInfo | null) => {
    if (accountInfo) {
      startApplication();
    } else {
      MSGraphAuth.login();
    }
  })
  .catch((error: any) => {
    if (error === "There isn't any account detected.") {
      MSGraphAuth.login();
    } else {
      console.log(error);
    }
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
